import React from 'react';
import image from '../assets/images/success.gif'

const SuccessImage = () => {
    return <center style={{ background: 'white', padding: 50 }}>
        <img width="200" src={image} alt="success transaction" />
        <h2 style={{ color: 'green', marginBottom: 20 }}>Payment successful</h2>

    </center>
}
export default SuccessImage;